import { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { clearAuthData, isExpiredJwtToken } from '@uptime/shared/utils/general';

import AuthContext from 'context/AuthContext';
import usePermissions from 'shared/hooks/usePermissions';

const PrivateRoute = (props) => {
  const { isAllowed, ...restProps } = props;
  const { role } = useContext(AuthContext);
  const { hasPermissionsLoaded } = usePermissions();
  const rolesAreLoaded = Boolean(role);

  if (isExpiredJwtToken()) {
    clearAuthData();
    return <Redirect to="/app/login" />;
  }

  if (rolesAreLoaded && hasPermissionsLoaded && !isAllowed) {
    return <Redirect to="/app/forbidden" />;
  }

  return <Route {...restProps} />;
};

PrivateRoute.propTypes = {
  isAllowed: PropTypes.bool.isRequired,
};

export default PrivateRoute;
