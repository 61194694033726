import { useQuery } from '@apollo/client';
import { getWOCount } from '@uptime/shared/graphql/workOrders';
import { flowClient } from 'apolloClientConfiguration';
import { WO_COUNTER_STATUSES } from '@uptime/shared/constants/wo';

interface Props {
  accountId: string | null;
  isRepairer: boolean | null;
}

const useWOCount = ({ accountId, isRepairer }: Props) => {
  const filterName = isRepairer ? 'technicianUserId' : 'accountId';
  const { data: { workOrderList: { itemsCount: woCount = 0 } = {} } = {} } = useQuery(getWOCount, {
    variables: {
      filter: {
        [filterName]: accountId,
        statusIds: WO_COUNTER_STATUSES,
      },
    },
    skip: !accountId,
    client: flowClient,
  });

  return {
    woCount,
  };
};

export default useWOCount;
