import React from 'react';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import GroupIcon from '@mui/icons-material/Group';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import SettingsIcon from '@mui/icons-material/Settings';

const formatCount = (count = 0) => (Number(count) > 99 ? '99+' : count);
// eslint-disable-next-line
export default ({ woCount = 0 }) => [
  {
    url: 'technician/marketplace',
    title: 'Marketplace Information',
    icon: <LocalMallIcon />,
    permission: true,
  },
  {
    url: 'technician/my-customers',
    title: 'My Customers',
    icon: <FolderSharedIcon />,
    permission: true,
    children: [
      {
        url: 'technician/my-customers/facilities',
        title: 'Facilities',
        permission: true,
      },
      {
        url: 'technician/my-customers/devices',
        title: 'Devices',
        permission: true,
      },
    ],
  },
  {
    url: 'work-orders',
    title: 'Service Requests',
    icon: <AssignmentIcon />,
    badge: formatCount(woCount),
    permission: true,
  },
  {
    url: 'technician/my-team',
    title: 'My Team',
    icon: <GroupIcon />,
    permission: true,
  },
  {
    url: 'settings',
    title: 'Settings',
    icon: <SettingsIcon />,
    permission: true,

    children: [
      {
        url: 'settings/account',
        title: 'Account',
        permission: true,
      },
      {
        url: 'settings/business',
        title: 'Business',
        permission: true,
      },
    ],
  },
];
