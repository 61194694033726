import { useQuery } from '@apollo/client';

import { getUserDashboards } from '@uptime/shared/graphql/users';

import { userClient } from 'apolloClientConfiguration';
import usePermissions from 'shared/hooks/usePermissions';

export default () => {
  const {
    commonDashboardsPermission,
    activitiesDashboardPermission,
    warrantyAndContractsDashboardPermission,
    deviceDashboardPermission,
    financialsDashboardPermission,
  } = usePermissions();

  const hasExploFeatureEnabled =
    commonDashboardsPermission ||
    activitiesDashboardPermission ||
    warrantyAndContractsDashboardPermission ||
    deviceDashboardPermission ||
    financialsDashboardPermission;

  const { data } = useQuery(getUserDashboards, {
    client: userClient,
    skip: !hasExploFeatureEnabled,
  });

  return data?.getUserDashboards;
};
