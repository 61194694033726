import React, { useContext, useState } from 'react';
import Helmet from 'react-helmet';
import * as PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Popover,
  Toolbar,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useQuery } from '@apollo/client';

import { FACILITY_ROLE, REPAIRER_ROLE } from '@uptime/shared/constants';
import {
  clearAuthData,
  getImageUrl,
  getName,
  isPassedQuickStart,
  responseLens,
} from '@uptime/shared/utils/general';
import { getInProgressTasksCounts } from '@uptime/shared/graphql/checkList';
import { getRunTaskFilterForBadges } from '@uptime/shared/utils/checkList';
import UserAvatar from '@uptime/shared/components/UserAvatar';
import UserMenu from '@uptime/shared/components/UserMenu';

import usePermissions from 'shared/hooks/usePermissions';
import { goCheckItClient } from 'apolloClientConfiguration';
import { useCognito } from 'libs/providers/CognitoProvider';
import { SourceContext } from 'providers/SourceProvider';
import AuthContext from 'context/AuthContext';
import { JwtContext } from 'context/JwtContext';

import { StyledBadge, useStyles } from './styles';

const getBaseUserEmail = (profile) => profile && profile.baseUser && profile.baseUser.email;

const Header = ({ menuToggle, user, image: { url, fetching } }) => {
  const classes = useStyles();
  const { signOut: cognitoSignOut, isAuthorized } = useCognito();
  const { authorized, signOut: jwtSignOut } = useContext(JwtContext);
  const { systemSettings = {} } = useContext(SourceContext);
  const { role, name: userName, email: userEmail, userId } = useContext(AuthContext);
  const [title, setTitle] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const { runTasksPermission } = usePermissions();

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const avatarSrc = getImageUrl(user && user.image);
  const name = getName(user) || userName;
  const email = getBaseUserEmail(user) || userEmail;
  const isFinishedQuickStart =
    systemSettings.value !== undefined && !isPassedQuickStart(systemSettings.value) && role === FACILITY_ROLE;

  const logout = () => {
    isAuthorized && cognitoSignOut();
    authorized && jwtSignOut();
    clearAuthData();
  };

  const { data } = useQuery(getInProgressTasksCounts, {
    variables: {
      userId,
      filter: getRunTaskFilterForBadges(),
    },
    skip: !userId || role === REPAIRER_ROLE || !runTasksPermission,
    client: goCheckItClient,
  });
  const inProgressRunTaskCount = responseLens(data, 'inProgress.itemsCount', 0);
  const overdueRunTaskCount = responseLens(data, 'overdue.itemsCount', 0);
  const activeRunTaskCount = inProgressRunTaskCount + overdueRunTaskCount;

  return (
    <AppBar position="fixed" className={classes.appBar} color="default">
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          edge="start"
          onClick={menuToggle}
          className={classes.menuButton}
          size="large"
        >
          <Badge color="error" variant="dot" invisible={activeRunTaskCount === 0}>
            <MenuIcon />
          </Badge>
        </IconButton>
        <Typography variant="h3" className={classes.title} data-testid="headerTitle">
          <Helmet onChangeClientState={(newState) => setTitle(newState.title ? newState.title[0] : '')} />
          {title}
        </Typography>
        <List className={classes.accountPreview}>
          <ListItem
            className={classes.accountPreviewItem}
            onClick={handleClick}
            data-testid="accountDropDownButton"
          >
            <ListItemAvatar className={classes.accountPreviewAvatar}>
              <StyledBadge variant="dot" color="primary">
                <UserAvatar isLoading={fetching} src={url || avatarSrc} name={name} />
              </StyledBadge>
            </ListItemAvatar>
          </ListItem>
        </List>
        <Popover
          classes={{
            paper: classes.paperOverride,
          }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <UserMenu
            fullName={name}
            userEmail={email}
            userAvatarUrl={url || avatarSrc}
            isLeft={isFinishedQuickStart}
            logout={logout}
            onStart={handleClose}
          />
        </Popover>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  menuToggle: PropTypes.func.isRequired,
  user: PropTypes.object,
  auth: PropTypes.object,
  image: PropTypes.oneOfType([
    PropTypes.shape({
      url: PropTypes.string,
      fetching: PropTypes.bool,
    }),
    PropTypes.func,
  ]).isRequired,
};

export default Header;
